

.navbar .nav-link {
    color: white !important; 
    font-weight: 350;
}

.navbar-style-1 {
    padding: 10px;
    min-width: 100%;
    margin-bottom: 20px;
    background-color: rgba(32, 31, 31, 0.45);
}

.navbar-style-1 .nav-link:hover {
    font-weight: bold;
  }

.set-right {
    text-align: right;
    justify-content: end;
    
}

.white-box-style {
    background-color: white;
    float: right;
}