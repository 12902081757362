body {
    background-color: #082b4dfc;
    min-width: 1000px;
}

.no-drop {
    position: relative;
}

.no-drop:after {
    text-shadow: 0px 0px black;
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1; /* Place the pseudo element right under the content */
    top: 0;
    left: 0;
    background: linear-gradient(to top, rgb(137, 136, 136) 100%, transparent 100%);
    animation-name: highlight;
    animation-duration: 3s;
}

@keyframes highlight {
    0% {
        width: 0%; 
        opacity: 0; 
    }
    50% {
        width: 100%;
        opacity: 1;
        
    }
}

.bold-on-hover:hover {
    font-weight: bolder;
}

.reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}
  
.reveal.active {
    transform: translateY(0);
    opacity: 1;
}

.do-hover {
    transition: all 0.2s ease;
}

.do-hover:hover {
    filter: drop-shadow(2px 2px 2px black);
    transform: scale(1.01);

}

hr {
    border:rgba(11, 11, 11, 0.911);
}

h2 {
    text-align: left;
    font-style: italic;
    font-size: medium;
    margin: 20px;
}

.Split {
    display: grid;
    grid-template-columns: 1fr .7fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.skill-rack-style {
    display: flex; /* Display the icons in a row */
    align-items: center; /* Center the icons vertically */
    justify-content: flex-end; /* Align the icons to the right */
    margin: 5px;
}

.skill-rack-style > img {
    margin: 10px
}

ul {
    margin: 20px;
    margin-top: 0px;
    padding-left: 0;
}

.two-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 30px;
}

/* For larger screens, two columns */
@media (min-width: 768px) {
    .two-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* For smaller screens, one column */
  @media (max-width: 767px) {
    .two-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }

.title-content {
    padding: 20px;
    margin: 20px;
}


.left-title-content {
    margin: 45px;
}

.left-title-content > h2 {
    color: white;
    margin-bottom: 10px;
    line-height: 65px;
}


.main-title {
    color: white;
    line-height: 65px;
}

.title {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 30px;
    margin-bottom: 0px;
    background-color: rgba(255, 255, 255, 0.95);
    color: black;
    padding: 20px;
    border: 2px solid black;
}

.more-padding {
    padding: 30px;
}

.who-box {
    box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.1);
    border: 3px solid black;
    margin: 20px;
    padding: 10px;
    background-color: rgba(41, 54, 87, 0.8)
}

/* SECTION STYLING */

.h-section-center {
    background-color: rgba(255, 255, 255, 0.9);
    margin-top: 150px;
    justify-content: center;
    align-items: center;
    display: flex;

}

.h-section-center > h1 {
    width: 50%;
    margin-top: 20px;
    text-align: left;
    padding: 20px
}

.h-section-center > h2 {
    width: 50%;
    margin: 20px;
    padding: 20px;
    text-align: left;
    padding: 20px
}

.h-section-center > h4 {
    width: 50%;
    margin-top: 20px;
    text-align: left;
}

.dock {
    display: flex;
    justify-content: space-between;
}

.dock-left {
    align-self: flex-start;
}

.dock-right {
    align-self: flex-end;
}

/* FUNCTIONS */

.resume-card {
    margin-left: 35px;
    margin-right: 35px;
    border-radius: 30px;
    margin-top: 10px;
    opacity: 90%;
}

.button-style-1 {

}

.button-style-1:hover {
    background-color: darkgray;
}

.project-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: rgba(11, 11, 11, 0.911);
    border-radius: 5px;
    overflow-wrap: break-word;
    display: block;
    overflow: auto;
    margin: 30px;
    margin-left: 10px;
    margin-right: 10px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    overflow: scroll;
    height: 70vh;
    
}

.project-content {
    margin: 15px;
    color: white;
}

/* BUTTONS */

.meet-element {
    text-align: left; 
    margin-left: 50px;
    margin: 40px;
}

.meet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 50%  
}

/* OBJECTS */

.square {
    width: 100%;
    height: 100%;
    background-color: lightslategray; /* change this to your desired color */
}

/* MISC */

.down-arrow {
    border: solid white;
    border-width: 0 10px 10px 0;
    display: inline-block;
    padding: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    filter: drop-shadow(4px 4px 2px black);
}

.center-obj {
    transform: translateY(200%);
    margin: auto;
    width: 5%;
    padding: 10px;
    position: relative;
    bottom: 0;
    animation: bounce 4s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    color: white;
}


@keyframes bounce {
    from { transform: translate3d(0, 0, 0);}
    to { transform: translate3d(0, 100px, 0);}
}

.fade-in {
opacity: 0; /* Set the initial opacity to 0 */
transition: opacity 1s ease-in-out; /* Add a transition effect that lasts 1 second */
}

.fade-in.show {
opacity: 1; /* When the "show" class is added to the element, set opacity to 1 */
}
  

@keyframes highlight {
    0% {
        width: 0%; 
        opacity: 0; 
    }
    50% {
        width: 100%;
        opacity: 1;
        
    }
}

.the-page-style {
    margin: 20px;
}

h2 {
    color : white;
}